import React, { useContext, useEffect, useState } from "react";
import { Card, CardActionArea, CardMedia, Typography, Box, useTheme, Button, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { orderStatusUpdate } from "../../../../store/slices/accounts/orders/OrdersSlice";
import { productImageGet } from "../../../../store/slices/accounts/orders/DeliveryAgentReviews";
import AutoAdjustText from "../../../../utils/AutoAdjustText";
import useScreenSize from "../../../../utils/ScreenWidthHight";
import { isEmpty } from "../../../../utils";
import useRazorpay from "react-razorpay";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../../Context";
import razorpayPay from "../../../../utils/Payment";
import { updateRetailOrderAfterPayment } from "../../../../store/slices/accounts/cart/CartSlice";

const OrderDetailsCard = ({ type, Color, isPaymentFailed, item, id, EmailID, handelCancelModalOpen, Handle_Exchange_Modal_Open, Handle_Return_Cancel_Modal_Open, setIsReturn, Line_Item_Order_Status
}) => {
    const theme = useTheme();
    const [isReturn_Availble, setIs_Return_Availble] = useState(false)
    const [isCancel_Availble, setIs_Cancel_Availble] = useState(false)
    const [isReturn_Cancel_Availble, setIs_Return_Cancel_Availble] = useState(false)
    const winsize = useScreenSize()


    useEffect(() => {
        if (!Boolean(item?.Exchange_Reference_Retail_Order_Id)) {
            if ((item.Order_Status === "DELIVERED"))
                setIs_Return_Availble(isBeforeReturnDate(item.Order_Status, item.Order_Status_Dates_Json.DELIVERED.Date, item.Return_Days))
            else
                setIs_Return_Availble(false)
        }
        if (["OUT-FOR-DELIVERY", "SHIPPED", "READY_FOR_SHIPPING", "ACCEPTED", "NEW"].includes(item.Order_Status) && !Boolean(item?.Exchange_Reference_Retail_Order_Id)) {
            setIs_Cancel_Availble(true)
        }
        else if (Boolean(item?.Exchange_Reference_Retail_Order_Id) && ["NEW"].includes(item.Order_Status?.toUpperCase())) {
            setIs_Cancel_Availble(true)
        }
        else {
            setIs_Cancel_Availble(false)
        }

        if (["RETURN"].includes(item?.Order_Status) && (!["DECLINED", "REACHED"].includes(item?.Return_Status))) {
            setIs_Return_Cancel_Availble(true)
        } else {
            setIs_Return_Cancel_Availble(false)
        }
    }, [item])

    const isBeforeReturnDate = (Order_Status, Created_Date, Return_Days, Return_Status = "") => {
        const date = new Date(Created_Date);
        const compareDate = new Date(date.setDate(date.getDate() + Return_Days));
        const currentDate = new Date();
        if (item?.Return_Exchange_Request_Count < 2) {
            if (Return_Status === "DECLINED" && item?.Order_Status === "EXCHANGE") {
                return ((currentDate <= compareDate));
            }
            return ((currentDate <= compareDate) && (["DELIVERED"].includes(Order_Status)));
        }
        else {
            return false
        }
    }

    return (
        <>
            {<Box >
                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '24px', fontWeight: 'bold' }}>Order Details</Typography>
            </Box>}
            <Card sx={{ width: { md: '90%', xs: "100%" }, borderRadius: '24px', border: '1px solid', borderColor: theme.palette.primary.main, height: { md: '258px', sm: '258px', xs: '140px' } }}>
                <Box display="flex" height="100%">
                    <CardActionArea style={{ width: '50%' }} >
                        <Link to={`/${type || "retail"}/products/${item?.Product_Id}`} style={{ width: "100%", height: "100%" }} >
                            <CardMedia
                                component="img"
                                height="100%"
                                image={item?.Product_Image}
                                alt="product_Image"
                                sx={{ objectFit: "fill" }}
                            // onClick={() => navigate(`/${type || "retail"}/products/${Product_Id}`)}

                            />
                        </Link>
                    </CardActionArea>
                    <Box width="50%" p={2} display="flex" flexDirection="column" justifyContent="space-between" >
                        <Box>
                            <AutoAdjustText style={{ width: "100%", fontSize: { md: "17px", sm: '17px', xs: '10px' } }} numLines={1} > {item?.Product_Name} </AutoAdjustText>
                            <Typography variant="body1" sx={{ fontSize: { md: "17px", sm: '17px', xs: '10px' } }}>Seller: <strong>{item?.Partner_Name}</strong></Typography>
                            <Typography variant="body1" sx={{ fontSize: { md: "17px", sm: '17px', xs: '10px' } }}>Order ID: <strong>{item?.Order_Id}</strong></Typography>
                            {/* <Typography variant="body1" sx={{ fontSize: { md: "17px", sm: '17px', xs: '10px' } }}>Product ID: <strong>{Product_Id}</strong></Typography> */}
                            <Typography variant="body1" sx={{ fontSize: { md: "17px", sm: '17px', xs: '10px' } }}>Size: <strong>{item?.Size}</strong></Typography>
                            <Typography variant="body1" sx={{ fontSize: { md: "17px", sm: '17px', xs: '10px' }, whiteSpace: 'nowrap' }}>Ordered Date: <strong>{item?.Created_Date?.slice(0, 16)}</strong></Typography>
                            <Typography variant="body1" sx={{ fontSize: { md: "17px", sm: '17px', xs: '10px' } }}>Quantity: <strong>{item?.Quantity}</strong></Typography>
                        </Box>

                        <Box sx={{ textAlign: "right", width: "100%", display: "flex", justifyContent: "flex-end", ...(winsize.screenSize.width < 600 ? { paddingY: 1 } : {}) }} >
                            {
                                (isReturn_Availble && !isPaymentFailed) &&

                                <Box sx={{ display: "flex", columnGap: 3 }} >
                                    <Button variant="contained"
                                        sx={{ fontSize: winsize.screenSize.width < 600 ? ".5rem" : "1rem" }}
                                        size={winsize.screenSize.width < 600 ? "small" : "medium"} onClick={() => {
                                            setIsReturn(true)
                                            handelCancelModalOpen()
                                        }} >Return</Button>
                                    <Button variant="contained"
                                        sx={{ fontSize: winsize.screenSize.width < 600 ? ".5rem" : "1rem" }}
                                        size={winsize.screenSize.width < 600 ? "small" : "medium"}
                                        onClick={() => {
                                            Handle_Exchange_Modal_Open()
                                        }} >Exchange</Button>
                                </Box>
                            }
                            {
                                ((item?.Return_Status === "DECLINED" && item?.Order_Status === "EXCHANGE") && !isPaymentFailed) &&

                                <Box sx={{ display: "flex", columnGap: 3 }} >
                                    <Button variant="contained"
                                        sx={{ fontSize: winsize.screenSize.width < 600 ? ".5rem" : "1rem" }}
                                        size={winsize.screenSize.width < 600 ? "small" : "medium"} onClick={() => {
                                            setIsReturn(true)
                                            handelCancelModalOpen()
                                        }} >Return</Button>
                                </Box>
                            }

                            {
                                (isCancel_Availble && !isPaymentFailed) &&

                                <Button variant="contained"
                                    sx={{ fontSize: winsize.screenSize.width < 600 ? ".5rem" : "1rem" }}
                                    size={winsize.screenSize.width < 600 ? "small" : "medium"}
                                    onClick={() => {
                                        handelCancelModalOpen()
                                    }} >Cancel</Button>
                            }
                            {
                                (isReturn_Cancel_Availble && !isPaymentFailed) &&



                                <Button variant="contained"
                                    sx={{ fontSize: winsize.screenSize.width < 600 ? ".5rem" : "1rem" }}
                                    size={winsize.screenSize.width < 600 ? "small" : "medium"}
                                    onClick={() => {
                                        Handle_Return_Cancel_Modal_Open()
                                    }} >Return Cancel</Button>
                            }

                            {
                                isPaymentFailed &&
                                <RetryPayment item={item} />
                            }

                            {
                                (item.Return_Exchange_Request_Count === 2 && item.Order_Status === "DELIVERED") &&
                                (<Box>
                                    <Typography>Max Return Chances Reached</Typography>
                                </Box>)
                            }


                        </Box>

                    </Box>
                </Box>
            </Card >

            {type !== "grocery" &&
                <Box>
                    <Box sx={{ marginY: 2 }}>
                        <Box>
                            <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '16px' }, fontWeight: "bold" }}>Delivery Address</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>{item?.User_Shipping_Address_Full?.NAME}</Typography>

                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>D-No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{item?.User_Shipping_Address_Full?.DOOR_NO}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                                <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{`${item?.User_Shipping_Address_Full?.LAND_MARK}, ${item?.User_Shipping_Address_Full?.STREET}, ${item?.User_Billing_Address_Full?.CITY}, ${item?.User_Billing_Address_Full?.STATE}, ${item?.User_Billing_Address_Full?.COUNTRY}`}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Pin Code: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{item?.User_Shipping_Address_Full?.ZIP}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{item?.User_Shipping_Address_Full?.MOBILE}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ marginY: 2 }}>
                        <Box>
                            <Typography sx={{ fontSize: { md: '24px', sm: '24px', xs: '16px' }, fontWeight: "bold" }}>Billing Address</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>{item?.User_Billing_Address_Full?.NAME}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>D-No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{item?.User_Billing_Address_Full?.DOOR_NO}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                                <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{`${item?.User_Billing_Address_Full?.LAND_MARK}, ${item?.User_Billing_Address_Full?.STREET}, ${item?.User_Billing_Address_Full?.CITY}, ${item?.User_Billing_Address_Full?.STATE}, ${item?.User_Billing_Address_Full?.COUNTRY}`}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}> Pin Code: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{item?.User_Billing_Address_Full?.ZIP}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ color: theme.palette.text.secondary, mr: '2px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                                <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{item?.User_Billing_Address_Full?.MOBILE}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {Boolean(item?.Agent_Mobile) && (
                        <Box sx={{ marginY: 2, display: "flex", alignItems: "center" }}>
                            <Box>

                            </Box>
                            <Box
                                component="img"
                                src={item?.Agent_Photo}
                                alt="Agent Profile"
                                sx={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50px",
                                    marginRight: "10px",
                                }}
                            />

                            <Box>
                                <Typography sx={{ fontWeight: "bold", fontSize: { xs: '16px', sm: '24px', md: '24px' } }}>Delivery Agent </Typography>
                                <Typography sx={{ fontWeight: "bold", fontSize: '16px' }}>{item?.Agent_Name}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography sx={{ color: theme.palette.text.secondary, }}>+91&nbsp;</Typography>

                                    <Typography>{item?.Agent_Mobile}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            }
        </>
    );
};

// const OrderDetailsCard = () => {
//     const [Razorpay] = useRazorpay()
//     return (
//         <Box>
//             <Typography> Hello</Typography>
//         </Box>
//     )
// }

export default OrderDetailsCard;


export const RetryPayment = ({ item }) => {

    const [Razorpay] = useRazorpay()
    const userDetails = useSelector(state => state.auth);
    const { toolTipActive } = useContext(GlobalContext)
    const navigate = useNavigate()
    const winsize = useScreenSize()
    return (
        <Button
            variant="contained"
            sx={{ fontSize: winsize.screenSize.width < 600 ? ".5rem" : "1rem" }}
            size={winsize.screenSize.width < 600 ? "small" : "medium"}
            onClick={() => {
                razorpayPay(Razorpay, { user: userDetails.user, amount: item.Payment_Amount }, async (status, response) => {
                    if (status) {
                        const updateResponse = await updateRetailOrderAfterPayment({ Transaction_Id: item.Transaction_Id, Payment_Transaction_Id: response.razorpay_payment_id })
                        if (updateResponse.status) {
                            toolTipActive("success", 'Order created successfully.');
                            navigate(0)
                        }
                        else {
                            toolTipActive("error", updateResponse.error);
                        }
                    } else {
                        toolTipActive("error", 'Payment failed, please try again.');
                    }
                });
            }}
        >
            Retry Payment
        </Button>
    )
}


export const GroceryShippingAddressComponent = (
    { Partner_Name, productImage, Product_Id, Order_Id, Product_Name, Order_Date, Delivered_Date,
        Quantity, customerName, cAddress, cPinCode, cPhoneNumber, billingName, billingAddress, billingPinCode, billingPhoneNumber,
        agentName, agentPhoneNumber, Order_Status, item, id, EmailID, type, agentProfileImage }
) => {
    const theme = useTheme()
    return (
        <Box>
            <Box sx={{ marginY: 2 }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { md: '24px', sm: '24px', xs: '16px' }, }}>Delivery Address</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>{customerName}</Typography>



                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                        <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{cAddress}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Pin Code:</Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }} >{cPinCode}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{cPhoneNumber}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ marginY: 2 }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { md: '24px', sm: '24px', xs: '16px' }, }}>Billing Address</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>{billingName}</Typography>

                    <Box sx={{ display: 'flex', flexDirection: "row" }}>
                        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Address:</Typography>
                        <Typography sx={{ ml: '5px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingAddress}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Pin Code: </Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingPinCode}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ color: theme.palette.text.secondary, mr: '2px', fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Phone No: </Typography>
                        <Typography sx={{ ml: "2px", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{billingPhoneNumber}</Typography>
                    </Box>
                </Box>
            </Box>
            {Boolean(agentPhoneNumber) &&
                <Box sx={{ marginY: 2, display: "flex", alignItems: "center" }}>
                    <Box>

                    </Box>
                    <Box
                        component="img"
                        src={agentProfileImage}
                        alt="Agent Profile"
                        sx={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                            marginRight: "10px",
                        }}
                    />

                    <Box>
                        <Typography sx={{ fontWeight: "bold", fontSize: { xs: '16px', sm: '24px', md: '24px' } }}>
                            Delivery Agent
                        </Typography>
                        <Typography sx={{ fontWeight: "bold", fontSize: '16px' }}>{agentName},</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography sx={{ color: theme.palette.text.secondary, }}>Phone No : </Typography>
                            <Typography>  {agentPhoneNumber}</Typography>
                        </Box>
                    </Box>
                </Box>}

        </Box>
    )
}