import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { Nibuspost_Order_History, OrderCancelAndReturnUpdate, productFetch } from "../../../../store/slices/accounts/orders/OrdersSlice";
import PaymentSummary from '../components/PaymentSummary'
// import Order_Stepper from "../components/OrderStepper";
import OrderDetailsCard from '../components/OrderDetailsCard'
import { Link, useLocation, useNavigate, useParams, } from "react-router-dom";
import DeliveryAgentReview from "../components/DeliveryAgentReview";
import { deliveryAgentReviewGet } from "../../../../store/slices/accounts/orders/DeliveryAgentReviews";
import DeliveryReviews from "../components/DeliveryReviews";
import { CancelModalComp } from "../components/CancelModal";
import { useTheme } from "@emotion/react";
import { isEmpty } from "../../../../utils";
import ExchangeModal from "../components/ExchangeModal";
import useScreenSize from "../../../../utils/ScreenWidthHight";
import { Order_Stepper } from "../components/OrderStepper";
import ReturnCancelModal from "../components/ReturnCancelModal";
import { GlobalContext } from "../../../../Context";

const OrdersDetailsPage = () => {
    const { id } = useParams();
    const userDetails = useSelector(state => state.auth);
    const EmailID = userDetails?.user?.Email_Id;
    const [product, setProduct] = useState([]);
    const [deliveryReviewsGet, setDeliveryReviewsGet] = useState([]);
    const [yourComments, setYourComments] = useState([]);
    const [productNewdata, setProductNewData] = useState({})
    const [PopUp_Message, setPopUp_Message] = useState({ Message: "", Title: "" })
    const auth = useSelector(state => state.auth)
    const [isReturn, setIsReturn] = useState(false)
    const [pageNotFoundStatus, setPageNotFoundStatus] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [exchangeModel, setExchangeModal] = useState(false)
    const [returnCancelModal, setReturnCancelModal] = useState(false)
    const winsize = useScreenSize()
    const Order_Stepper_Ref = useRef([])
    const [Color, setColor] = useState("green")
    const [stepperLabels, setStepperLabels] = useState(["Loading", "Loading", "Loading", "Loading", "Loading"])
    const [OrderHistory, setOrderHistory] = useState([])
    const { toolTipActive } = useContext(GlobalContext)
    const [ActiveOrderStatus, setActiveOrderStatus] = useState("")
    const [isPaymentFailed, setIsPaymentFailed] = useState(false)
    const [returnCancelLoading, setReturnCancelLoding] = useState(false)
    const dispatch = useDispatch();
    const theme = useTheme()

    useEffect(() => {
        if (id && auth.isAuthenticated) {
            productsCall(id);
            deliveryCommentsCall(id);
            Nibuspost_Order_History_Call(id)
        }
        else {
            if (auth.isAuthenticated) {
                setPageNotFoundStatus(true)
            }
        }
    }, []);


    useEffect(() => {
        const Success_Labels = ['NEW', "ACCEPTED", 'READY_FOR_SHIPPING', "SHIPPED", 'OUT_FOR_DELIVERY', 'DELIVERED']
        const Cancel_Labels = ['NEW', 'CANCEL', "SETTLED"]
        const Return_Labels = ['NEW', 'RETURN', "ACCEPTED", "IN_TRANSIT", "REACHED", "SETTLED"]
        const Exchange_Labels = ['NEW', 'EXCHANGE', "ACCEPTED", "IN_TRANSIT", "REACHED"]
        // const Exchange_Labels = ['NEW', 'EXCHANGE', "ACCEPTED", "IN_TRANSIT", "REACHED"]
        const DECLINED_Labels = ['NEW', 'DECLINED', "SETTLED"]
        if (productNewdata.Payment_Transaction_Status === "PENDING") {
            setIsPaymentFailed(true)
        }
        if (productNewdata.Order_Status) {
            if (productNewdata.Order_Status && Success_Labels.indexOf(productNewdata.Order_Status) !== -1) {
                setStepperLabels(Success_Labels)
                setColor("green")
                if (productNewdata?.Exchange_Reference_Retail_Order_Id) {
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Order is Exchanged from " + productNewdata?.Exchange_Reference_Retail_Order_Id })

                }
                if (productNewdata.Order_Status === "READY_FOR_SHIPPING" || productNewdata.Order_Status === "SHIPPED") {
                    setColor("#1993b5")
                    // setColor("purple")
                }

            }
            else if (productNewdata.Order_Status && Cancel_Labels.indexOf(productNewdata.Order_Status) !== -1) {
                if (productNewdata?.Exchange_Reference_Retail_Order_Id) {
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Order is Exchange Order is Cancelled " + productNewdata?.Exchange_Reference_Retail_Order_Id })
                    setStepperLabels(['NEW', 'Exchange', 'CANCEL'])
                }
                else if (productNewdata?.Refund_Online_Amount > 0 || productNewdata?.Refund_Wallet_Amount > 0) {
                    setStepperLabels(Cancel_Labels)
                    setActiveOrderStatus("SETTLED")
                }
                else {
                    setStepperLabels(Cancel_Labels)
                }
                setColor("red")
            }
            else if (productNewdata.Order_Status && DECLINED_Labels.indexOf(productNewdata.Order_Status) !== -1) {
                if (productNewdata?.Exchange_Reference_Retail_Order_Id) {
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Order is Exchange Order is Declined " + productNewdata?.Exchange_Reference_Retail_Order_Id })

                    setStepperLabels(['NEW', 'Exchange', 'DECLINED'])
                }
                else if (productNewdata?.Refund_Online_Amount > 0 || productNewdata?.Refund_Wallet_Amount > 0) {
                    setStepperLabels(DECLINED_Labels)
                    setActiveOrderStatus("SETTLED")
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Your order is Declined" })

                }
                else {
                    setStepperLabels(DECLINED_Labels)
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Your order is Declined" })

                }
                setColor("red")
            }
            else if (productNewdata.Order_Status && Return_Labels.indexOf(productNewdata.Order_Status) !== -1) {
                if ((productNewdata?.Return_Status === "DECLINED")) {
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Your Return Order is Declined" })
                    setStepperLabels(['NEW', 'RETURN', 'DECLINED'])
                    setActiveOrderStatus("DECLINED")
                    setColor("red")
                }
                else if (productNewdata?.Refund_Online_Amount > 0 || productNewdata?.Refund_Wallet_Amount > 0) {
                    setStepperLabels(Return_Labels)
                    setActiveOrderStatus("SETTLED")
                    setColor("purple")
                }
                else if ((["REACHED", "ACCEPTED", "IN_TRANSIT"].includes(productNewdata?.Return_Status))) {
                    setStepperLabels(Return_Labels)
                    setActiveOrderStatus(productNewdata?.Return_Status)
                    setColor("purple")
                }
                else {
                    setStepperLabels(Return_Labels)
                    setColor("purple")
                }
            }
            else if (productNewdata.Order_Status && Exchange_Labels.indexOf(productNewdata.Order_Status) !== -1) {
                if (productNewdata?.Exchange_Reference_Retail_Order_Id) {
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Order is Exchanged from " + productNewdata?.Exchange_Reference_Retail_Order_Id })
                }
                if ((productNewdata?.Return_Status === "DECLINED")) {
                    setStepperLabels(['NEW', 'EXCHANGE', "DECLINED"])
                    setPopUp_Message({ Message: productNewdata?.Decline_Reason, Title: "Your exchange request is declined" })
                    setActiveOrderStatus("DECLINED")
                    setColor("red")
                }
                // else if (productNewdata?.Refund_Online_Amount > 0 && productNewdata?.Refund_Online_Payment_Transaction_Id?.length > 0) {
                //     console.log("151")
                //     setStepperLabels(Exchange_Labels)
                //     setActiveOrderStatus("SETTLED")
                //     setColor("green")
                // }
                else if ((["REACHED", "ACCEPTED", "IN_TRANSIT"].includes(productNewdata?.Return_Status))) {
                    setStepperLabels(Exchange_Labels)
                    setActiveOrderStatus(productNewdata?.Return_Status)
                    setColor("green")
                }
                else {
                    setStepperLabels(Exchange_Labels)
                    setColor("green")
                }
            }
        }
    }, [productNewdata])


    const Connecter_Width = useMemo(() => {
        try {
            if (Order_Stepper_Ref?.current["Outter"]) {
                const outter = Order_Stepper_Ref.current["Outter"];
                const width = outter.getBoundingClientRect().width;
                let connecterWidth = Math.ceil((width - (stepperLabels.length * 24)) / stepperLabels.length)
                if (stepperLabels.length === 3 && winsize.screenSize.width > 1400) {
                    connecterWidth = outter.getBoundingClientRect().width - (connecterWidth * 1.8)
                }
                else if (stepperLabels.length === 3 && winsize.screenSize.width > 1100) {
                    connecterWidth = outter.getBoundingClientRect().width - (connecterWidth * 1.9)

                }
                else if (stepperLabels.length === 3 && winsize.screenSize.width > 600) {
                    connecterWidth = outter.getBoundingClientRect().width - (connecterWidth * 2)

                }
                else if (stepperLabels.length === 3 && winsize.screenSize.width < 400) {
                    connecterWidth = outter.getBoundingClientRect().width - (connecterWidth * 2.5)

                }
                else if (stepperLabels.length === 3 && winsize.screenSize.width < 600) {
                    connecterWidth = outter.getBoundingClientRect().width - (connecterWidth * 2.35)

                }
                else
                    connecterWidth = connecterWidth + ((4 * connecterWidth) / 100)
                return { connecterWidth: connecterWidth };
            } else {
                return { connecterWidth: 100 };
            }
        } catch (err) {
            // console.log(err.message);
            return { connecterWidth: 100 };
        }
    }, [Order_Stepper_Ref?.current["Outter"], stepperLabels, winsize.screenSize.width]);


    const productsCall = async (id) => {
        const response = await productFetch({ id: id });
        try {
            if (response.status) {
                setProduct(response.data[0].Product_Id);
                setProductNewData({
                    ...response.data[0], User_Shipping_Address_Full: JSON.parse(response.data[0].User_Shipping_Address_Full), User_Billing_Address_Full: JSON.parse(response.data[0].User_Billing_Address_Full), Order_Status_Dates_Json: JSON.parse(response.data[0].Order_Status_Dates_Json),
                    // Order_Status: "DELIVERED"
                })
                setActiveOrderStatus(response.data[0].Order_Status)
                // setActiveOrderStatus("DELIVERED")
            } else {
                setPageNotFoundStatus(true)
            }
        } catch (error) {
        }

    };

    const Nibuspost_Order_History_Call = async (id) => {
        const response = await Nibuspost_Order_History(id)
        if (response.status) {
            setOrderHistory(response.data)
        } else {
            setOrderHistory({})
        }
    }

    const deliveryCommentsCall = async (id) => {
        try {
            const formData = { Order_Line_Item_Id: [id], User_Email_Id: EmailID };
            const response = await deliveryAgentReviewGet(formData);
            if (response.status && response.data) {
                const filteredData = response.data.results.filter(filtr => filtr.User_Email_Id === EmailID);
                setYourComments(filteredData);
                setDeliveryReviewsGet(filteredData);
            } else {
            }
        } catch (error) {
        }
    };


    const handelCancelModal = () => {
        setCancelModal(false)
    }
    const handelCancelModalOpen = () => {
        setCancelModal(true)
    }


    if (!pageNotFoundStatus)
        return (
            <Grid container p={{ md: 5, xs: 2 }} sx={{ overflow: "hidden" }} >
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "10px" }} >
                        {
                            <OrderDetailsCard
                                Color={Color}
                                isPaymentFailed={isPaymentFailed}
                                item={productNewdata}
                                id={id}
                                EmailID={EmailID}
                                handelCancelModalOpen={handelCancelModalOpen}
                                Handle_Exchange_Modal_Open={() => setExchangeModal(true)}
                                Handle_Return_Cancel_Modal_Open={() => setReturnCancelModal(true)}
                                setIsReturn={setIsReturn}
                                Line_Item_Order_Status={productNewdata?.Line_Item_Order_Status}
                            />}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ mt: { md: '50px', sm: '50px', xs: '0px' } }} ref={(el) => (Order_Stepper_Ref.current["Outter"] = el)}  >
                    {!isPaymentFailed &&
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >
                            <Typography sx={{ fontSize: { md: '30px', xs: '16px' }, fontWeight: "bold" }}>
                                Track Your Order
                            </Typography>
                            {PopUp_Message.Title && <Tooltip title={<Box   >
                                <Typography sx={{ fontSize: ".8rem" }}> {PopUp_Message.Message}</Typography>
                            </Box>
                            } >
                                {productNewdata?.Exchange_Reference_Retail_Order_Id ? <Link to={'/accounts/orderdetails/' + productNewdata?.Exchange_Reference_Retail_Order_Id} target="_blank" style={{ textDecoration: "none" }} >
                                    <Typography sx={{ color: Color, fontSize: { md: '18px', xs: '12px' }, cursor: (productNewdata?.Exchange_Reference_Retail_Order_Id) && "pointer" }}

                                    >{PopUp_Message.Title}</Typography>
                                </Link> :
                                    <Typography sx={{ color: Color, fontSize: { md: '18px', xs: '12px' }, cursor: (productNewdata?.Exchange_Reference_Retail_Order_Id) && "pointer" }}

                                    >{PopUp_Message.Title}</Typography>}
                            </Tooltip>}
                        </Box>
                    }
                    {!isPaymentFailed &&
                        <Order_Stepper
                            status={ActiveOrderStatus}
                            Line_Item_Order_Status={ActiveOrderStatus}
                            Color={Color}
                            Connecter_Width={Connecter_Width}
                            OrderHistory={OrderHistory}
                            Order_Labels={stepperLabels}
                            Active_Label={stepperLabels.indexOf(ActiveOrderStatus)}
                            Order_Status_Dates_Json={productNewdata?.Order_Status_Dates_Json}
                        />
                    }
                    <Typography sx={{ fontSize: { md: '30px', xs: '16px' }, marginTop: { md: '40px', sm: '40px', xs: '50px' }, fontWeight: "bold" }}>
                        Payment Summary
                    </Typography>
                    <PaymentSummary
                        Order_Details={productNewdata}
                        Order_Id={productNewdata?.Order_Id}
                        Partner_Selling_Price={productNewdata?.Base_Selling_Price}
                        Order_Type={"RETAIL"}
                        Quantity={productNewdata?.Quantity}
                        id={id}
                        EmailID={EmailID}
                        Igst={Number(productNewdata?.Sgst_Percentage + productNewdata?.Cgst_Percentage)}
                        Total_Tax={productNewdata?.Total_Tax}
                        Gift_Wrap_Amount={productNewdata?.Gift_Wrap_Amount}
                        Delivery_Person_Fee={productNewdata?.Shipping_Amount}
                        Gross_Price={productNewdata?.Mrp}
                        Order_Status={productNewdata?.Order_Status || ""}
                        Coupon_Discount={productNewdata?.Partner_Coupon_Amount}
                        Vat={productNewdata?.Vat_Percentage}
                        VatAmount={productNewdata?.Vat}
                        IgstAmount={productNewdata?.Sgst + productNewdata?.Cgst}
                        Total_Net_Price={productNewdata?.Mrp}
                    />
                    {(deliveryReviewsGet.length === 0 && !isEmpty(productNewdata?.Agent_Mobile)) && (
                        <Grid>
                            <DeliveryAgentReview data={productNewdata} EmailID={EmailID} setDeliveryReviewsGet={setDeliveryReviewsGet} deliveryCommentsCall={deliveryCommentsCall} />
                        </Grid>
                    )}

                    <Grid>
                        {Boolean(yourComments[0]) &&
                            <DeliveryReviews
                                rating={yourComments[0]?.Rating}
                                comments={yourComments[0]?.Comments}
                            />}
                    </Grid>
                </Grid>
                {cancelModal && <CancelModalComp cancelModal={cancelModal} handelCancelModal={handelCancelModal} id={id} productsCall={productsCall} isReturn={isReturn} Order_Details={productNewdata} />}
                {exchangeModel && <ExchangeModal Handle_Modal_Open={() => { setExchangeModal(false) }} Modal_Open={exchangeModel} Order_Details={productNewdata} productsCall={() => productsCall(id)} />}
                {returnCancelModal && <ReturnCancelModal
                    Modal_Open={returnCancelModal}
                    Handle_Modal_Open={(status) => setReturnCancelModal(status)}
                    Title={"Are you sure do you want to cancel ?"}
                    returnCancelLoading={returnCancelLoading}
                    yes={async () => {
                        setReturnCancelLoding(true)
                        const formData = {
                            "Order_Status": "CANCEL",
                        }
                        const response = await OrderCancelAndReturnUpdate(productNewdata.Order_Id, formData)
                        if (response.status) {
                            productsCall(id)
                            setReturnCancelModal(false)
                            toolTipActive("success", response.data.message)
                        }
                        else {
                            toolTipActive("error", response.data.message)
                        }
                        setReturnCancelLoding(false)
                    }}
                    no={() => setReturnCancelModal(false)}
                />}
            </Grid >
        );
    else {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <Typography sx={{ fontSize: "30px", fontWeight: "bold" }} >Page Not Found</Typography>
            </Box>
        )
    }
};

export default OrdersDetailsPage;

